import { useState } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button } from 'antd'
import { shallow } from 'zustand/shallow'

import * as S from './styles'

import { useAgenciesFilters } from '@/app'
import { useToggle } from '@/common'
import { CustomPagination, LoadingSpinner } from '@/components'
import { ViewAgencyModal, CreateAgencyModal } from '@/features/agency/components'
import { AgenciesTable } from '@/features/agency/components/Table/AgenciesTable'
import { agenciesQueries } from '@/features/agency/queries'
import { Agency } from '@/features/agency/types'
import { PageLayout } from '@/layouts'

const AgenciesPage = () => {
  const [isEditAgencyModalOpen, toggleEditAgencyModal] = useToggle(false)
  const [isCreateAgencyModalOpen, toggleCreateAgencyModal] = useToggle(false)

  const [selectedAgency, setSelectedAgency] = useState<Agency | undefined>(undefined)

  const { agenciesFilters, setFilters } = useAgenciesFilters(
    (state) => ({
      agenciesFilters: state.filters,
      setFilters: state.setFilters,
    }),
    shallow,
  )

  const {
    data: agenciesData,
    isLoading: isAgenciesLoading,
    isFetching: isAgenciesFetching,
  } = useQuery(agenciesQueries.list(agenciesFilters))

  function handleEditAgencies(agency: Agency | undefined) {
    setSelectedAgency(agency)
    toggleEditAgencyModal()
  }

  function handleSearch(search: string) {
    setFilters({ name: search })
  }

  function handlePagination(page: number, pageSize?: number) {
    setFilters({ page, per_page: pageSize })
  }

  const PageHeader = () => {
    return (
      <S.Header>
        <S.TitleContainer>
          <S.Title level={4}>Agências</S.Title>

          <S.Divider thickness={2} type="vertical" />

          {agenciesData && (
            <S.TotalAgenciesContent>
              <S.TotalAgenciesValue>{agenciesData.total}</S.TotalAgenciesValue>
              {agenciesData.total > 1 ? 'encontrados' : 'encontrado'}
            </S.TotalAgenciesContent>
          )}

          {(isAgenciesLoading || isAgenciesFetching) && <LoadingSpinner />}
        </S.TitleContainer>
        <S.Row>
          <S.SearchInput
            allowClear
            placeholder="Nome da Agência"
            defaultValue={agenciesFilters.name}
            onSearch={handleSearch}
          />
          <Button type="primary" icon={<PlusOutlined />} onClick={toggleCreateAgencyModal}>
            Cadastrar Agência
          </Button>
        </S.Row>
      </S.Header>
    )
  }

  return (
    <PageLayout title="Agências">
      {isCreateAgencyModalOpen && (
        <CreateAgencyModal isOpen={isCreateAgencyModalOpen} onClose={toggleCreateAgencyModal} />
      )}

      {isEditAgencyModalOpen && (
        <ViewAgencyModal
          isOpen={isEditAgencyModalOpen}
          onClose={() => handleEditAgencies(undefined)}
          data={selectedAgency as Agency}
        />
      )}

      <S.Container>
        <PageHeader />
        <AgenciesTable
          data={agenciesData?.agencies}
          isLoading={isAgenciesLoading}
          handleEditAgencies={handleEditAgencies}
        />
        <S.PaginationContainer>
          <CustomPagination
            scrollToTop
            page={agenciesFilters.page || 1}
            pageSize={agenciesFilters.per_page || 10}
            totalItems={agenciesData?.total || 0}
            totalPages={agenciesData?.total_pages || 1}
            isLoading={isAgenciesLoading}
            changePageValue={(page, pageSize) => handlePagination(page, pageSize)}
          />
        </S.PaginationContainer>
      </S.Container>
    </PageLayout>
  )
}

export { AgenciesPage }
