import { useNavigate } from 'react-router-dom'

import { useMutation } from '@tanstack/react-query'
import { toast } from 'sonner'

import { RefreshLoginResponse } from '../../types'

import { authStore } from '@/app'
import rolesStore from '@/app/stores/roles'
import { clearAllCaches } from '@/common'
import { api } from '@/services'

type RefreshLoginMutation = {
  refreshToken: string
  userId: string
  redirectPage: string | null
}

export const useRefreshLoginToken = () => {
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async ({ refreshToken, userId }: RefreshLoginMutation) => {
      return api.post<RefreshLoginResponse>(`/login_unico/refresh_token`, {
        refresh_token: refreshToken,
        user_id: userId,
      })
    },
    onSuccess: ({ data }, { redirectPage }) => {
      api.defaults.headers.common.Authorization = `Bearer ${data.IdToken}`

      // Atualizar auth stores
      authStore.setState(
        {
          accessToken: data.AccessToken,
          refreshToken: data.RefreshToken,
          idToken: data.IdToken,
          isUserAuthenticated: true,
        },
        false,
        'sign-in',
      )

      const isSystemAdmin = !!data.UserPermissions?.['*']?.includes('*')

      rolesStore.getState().getUserRoles(data.IdToken)
      rolesStore.setState({ userRoles: data.UserPermissions })
      rolesStore.setState({ isSystemAdmin })

      toast.success('Usuario validado com sucesso!')

      if (redirectPage) {
        return navigate(`/${redirectPage}`)
      } else {
        navigate(`/dashboard`)
      }
    },
    onError: (err) => {
      console.error('Error refreshing login token', err)

      toast.error('Erro ao validar usuário. Por favor, faça login novamente.')

      clearAllCaches()
      navigate('/auth/login')
    },
  })
}
