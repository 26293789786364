import { FileDoneOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { ColumnsType } from 'antd/es/table'

import * as S from './styles'

import { formatDate, truncateString } from '@/common'
import { BaseTable, TableAction, TooltipTable } from '@/components'
import { Agency, Responsible } from '@/features/agency/types'

type AgencyTableProps = {
  data: Agency[] | undefined
  isLoading: boolean
  handleEditAgencies: (agency: Agency) => void
}

const AgenciesTable = ({ data, isLoading, handleEditAgencies }: AgencyTableProps) => {
  const columns: ColumnsType<Agency> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: 50,
    },
    {
      title: 'Responsável',
      key: 'responsibles',
      dataIndex: 'responsibles',
      align: 'center',
      width: 100,
      render: (responsibles: Responsible[], agency) => {
        return (
          <TooltipTable
            displayItem={responsibles[0]?.name}
            data={responsibles}
            record={agency}
            columns={[
              {
                title: 'Responsável',
                key: 'name',
                dataIndex: 'name',
                width: 150,
                render: (name) => truncateString(name, 30),
              },
              {
                title: 'Email',
                key: 'email',
                dataIndex: 'email',
                width: 150,
                render: (email) => truncateString(email, 32),
              },
            ]}
          />
        )
      },
    },
    {
      title: 'Data de Criação',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      width: 125,
      render: (createdAt) => formatDate({ date: createdAt, dateFormat: 'DD/MM/YY HH:mm' }),
    },
    {
      title: 'Data de Atualização',
      dataIndex: 'updated_at',
      key: 'updated_at',
      align: 'center',
      width: 125,
      render: (updatedAt) => formatDate({ date: updatedAt, dateFormat: 'DD/MM/YY HH:mm' }),
    },
    {
      title: 'Ações',
      dataIndex: '',
      key: 'actions',
      align: 'center',
      width: 50,
      render: (_, agency) => {
        const dropdownOptions = [
          {
            key: '1',
            label: (
              <S.ButtonContent>
                <Button
                  icon={<FileDoneOutlined />}
                  type="link"
                  onClick={() => handleEditAgencies(agency)}
                >
                  Dados da Agência
                </Button>
              </S.ButtonContent>
            ),
          },
        ]

        return <TableAction dropdownOptions={dropdownOptions} />
      },
    },
  ]

  return (
    <BaseTable
      dataSource={data}
      columns={columns}
      rowKey={(record) => record._id}
      pagination={false}
      loading={isLoading}
    />
  )
}

export { AgenciesTable }
