import { ProductStatus } from '../../types'
import * as S from './styles'

import { statusTagRemap } from '@/common'

type BgStatusTagsProps = {
  status?: ProductStatus
  isBgView?: boolean
}

const statusToComponentMap: Record<ProductStatus, React.ComponentType<any>> = {
  'EM RASCUNHO DO PRODUTO': S.SketchTag,
  'PENDENTE DE ENVIO PARA AGÊNCIA': S.MarketingTag,
  'PENDENTE DE LIBERAÇÃO DA AGÊNCIA': S.AgencyTag,
  'PENDENTE DE CADASTRO NO PROTHEUS': S.ProtheusTag,
  'EM RASCUNHO NO E-COMMERCE': S.SketchEcommerceTag,
  'PENDENTE DE PRECIFICAÇÃO': S.PrecificationTag,
  PUBLICADO: S.PublishedTag,
  DESPUBLICADO: S.UnpublishedTag,
  DESCONTINUADO: S.DiscontinuedTag,
}

const DefaultTag: React.ComponentType<any> = S.StyledTag

const BgStatusTags = ({ status, isBgView }: BgStatusTagsProps) => {
  const TagComponent = statusToComponentMap[status as ProductStatus] || DefaultTag

  return (
    <TagComponent $isBgView={isBgView}>
      {statusTagRemap.get(status as ProductStatus) || status}
    </TagComponent>
  )
}

export { BgStatusTags }
