import { AgencyMutation } from '../../types'
import * as S from './styles'

import { truncateString } from '@/common'

interface AgencyCardProps {
  data: AgencyMutation
  onDelete: () => void
  index: number
  isEditing?: boolean
  isCreating?: boolean
}

const AgencyCard = ({ data, index, onDelete, isEditing, isCreating = false }: AgencyCardProps) => {
  return (
    <S.Container>
      <S.Column>
        <S.ResponsibleName>
          {truncateString(data.responsibles[index].name || '-', 42)}
        </S.ResponsibleName>
        <S.ResponsibleEmail>
          {truncateString(data.responsibles[index].email || '-', 42)}
        </S.ResponsibleEmail>
      </S.Column>
      {(isEditing || isCreating) && <S.DeleteAgencyButton onClick={onDelete} />}
    </S.Container>
  )
}

export { AgencyCard }
