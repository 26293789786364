import { CheckCircleOutlined } from '@ant-design/icons'
import { Typography, Divider as AntdDivider, Input } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 24px;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  h1 {
    font-weight: 400;
    font-size: ${rem(24)};
    color: ${({ theme }) => theme.colors.bgColor};
    margin: 0;
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Title = styled(Typography.Title)`
  font-weight: 400;
  margin-bottom: 0 !important;
`

export const Divider = styled(AntdDivider)<{ color?: string; thickness?: number }>`
  color: ${({ color }) => (color ? color : '#414243')};
  border-left: ${({ thickness }) => (thickness ? `${thickness}px` : '1px')} solid
    ${({ color }) => (color ? color : '#414243')};
  border-color: #414243;
  height: 18px;
  margin: 0 12px;
`

export const TotalAgenciesContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding-right: 8px;
`

export const TotalAgenciesValue = styled.strong`
  font-size: ${rem(18)};
  color: ${({ theme }) => theme.colors.blue};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
`

export const PaginationContainer = styled.div`
  margin-top: auto;
`

export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CircleOutlined = styled(CheckCircleOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 99%;
  background-color: #80c343;
`

export const SearchInput = styled(Input.Search)`
  .anticon-search {
    color: ${({ theme }) => theme.colors.linkActive};
  }
`
