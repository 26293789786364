import { PlusOutlined } from '@ant-design/icons'
import { Form, Input } from 'antd'

import * as S from './styles'

interface AgencyFormProps {
  isAgencyNameDisabled?: boolean
}

const AgencyForm = ({ isAgencyNameDisabled }: AgencyFormProps) => {
  return (
    <S.Container>
      <S.Column>
        <Form.Item
          name={'name'}
          label={'Nome da Agência'}
          rules={[
            { required: true, message: 'Campo obrigatório' },
            { min: 3, message: 'Mínimo de 3 caracteres' },
          ]}
        >
          <Input className="agency-name-input" disabled={isAgencyNameDisabled} />
        </Form.Item>
        <S.Row>
          <Form.Item
            name={'responsible'}
            label={'Responsável'}
            rules={[
              { required: true, message: 'Campo obrigatório' },
              { min: 3, message: 'Mínimo de 3 caracteres' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={'email'}
            label={'E-mail do Responsável'}
            rules={[
              { required: true, message: 'Campo obrigatório' },
              { type: 'email', message: 'E-mail inválido' },
            ]}
          >
            <Input />
          </Form.Item>

          <S.AddButton type="primary" shape="circle" htmlType="submit" icon={<PlusOutlined />} />
        </S.Row>
      </S.Column>
    </S.Container>
  )
}

export { AgencyForm }
