import { Tag } from 'antd'
import { rem, rgba } from 'polished'
import styled from 'styled-components'

interface BgTagsProps {
  $isBgView?: boolean
}

export const StyledTag = styled(Tag)<BgTagsProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${({ $isBgView }) => ($isBgView ? `${rem(27)}` : `${rem(21)}`)};
  width: fit-content;

  border-radius: 4px;
  font-size: ${({ $isBgView }) => ($isBgView ? `${rem(14)}` : `${rem(12)}`)};
  margin-top: ${({ $isBgView }) => ($isBgView ? null : 'auto')};
  font-weight: 600;
`

export const SketchTag = styled(StyledTag)`
  color: ${rgba('#733D99', 0.7)};
  border: 1px solid ${rgba('#935FB9', 0.3)};
  background-color: #f0e8f4;
`

export const MarketingTag = styled(StyledTag)`
  color: #733d99;
  border: 1px solid ${rgba('#733D99', 0.3)};
  background-color: #f5eff8;
`

export const AgencyTag = styled(StyledTag)`
  color: #db9200;
  border: 1px solid ${rgba('#DB9200', 0.3)};
  background-color: #fff7e8;
`

export const ProtheusTag = styled(StyledTag)`
  color: #ea7b26;
  border: 1px solid ${rgba('#EA7B26', 0.3)};
  background-color: rgba(234, 123, 38, 0.1);
`

export const SketchEcommerceTag = styled(StyledTag)`
  color: #b44e00;
  border: 1px solid ${rgba('#B44E00', 0.3)};
  background-color: #fdf2ea;
`

export const PrecificationTag = styled(StyledTag)`
  color: #004fc7;
  border: 1px solid ${rgba('#004FC7', 0.3)};
  background-color: #e6eefa;
`

export const PublishedTag = styled(StyledTag)`
  color: #1e9493;
  border: 1px solid ${rgba('#1E9493', 0.3)};
  background-color: #e9f5f5;
`

export const UnpublishedTag = styled(StyledTag)`
  color: #666666;
  border: 1px solid ${rgba('#666666', 0.3)};
  background-color: ${rgba('#FAFAFA4D', 0.3)};
`

export const DiscontinuedTag = styled(StyledTag)`
  color: #999999;
  border: 1px solid ${rgba('#999999', 0.3)};
  background-color: #fafafa;
`
