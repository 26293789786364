import { Button, Form } from 'antd'
import { toast } from 'sonner'

import { DiscontinuedForm } from '../../Form'
import * as S from './styles'

import { BaseModalProps } from '@/common'
import { BaseModal } from '@/components'
import { useProductDiscontinuedMutation } from '@/features/BG/mutations/product-discontinued'
import { Product } from '@/features/BG/types'

interface DiscontinuedModalProps extends BaseModalProps {
  data?: Product
}

const DiscontinuedModal = ({ isOpen, onClose, data }: DiscontinuedModalProps) => {
  const [form] = Form.useForm()

  const discontinueProductMutation = useProductDiscontinuedMutation()

  async function handleSubmit({ description }: { description: string }) {
    const promise = discontinueProductMutation.mutateAsync({
      productId: data?.id as string,
      description: description,
    })

    toast.promise(promise, {
      loading: 'Descontinuando produto...',
      success: 'Produto descontinuado com sucesso!',
      error: 'Erro ao descontinuar produto.',
    })

    await promise

    onClose()
  }

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title={'Confirmação de descontinuação'}
      width={717}
      footer={
        <>
          <Button onClick={onClose} type="link" disabled={discontinueProductMutation.isPending}>
            Cancelar
          </Button>

          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={discontinueProductMutation.isPending}
          >
            Confirmar
          </Button>
        </>
      }
    >
      <S.Container>
        <S.ExclamationTriangle />

        <S.Text>
          Tem certeza que deseja descontinuar o produto <br />
          &quot;
          <strong>{data?.name || data?.model}</strong>&quot; ?
        </S.Text>

        <DiscontinuedForm
          form={form}
          onSubmit={handleSubmit}
          isLoading={discontinueProductMutation.isPending}
        />
      </S.Container>
    </BaseModal>
  )
}

export { DiscontinuedModal }
