import { useEffect, useState } from 'react'
import { BsQuestionLg } from 'react-icons/bs'
import { FiFastForward } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

import {
  ArrowLeftOutlined,
  FilePdfOutlined,
  FileProtectOutlined,
  FormOutlined,
} from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Form, Skeleton, Steps, Tooltip } from 'antd'
import axios, { AxiosResponse } from 'axios'
import { toast } from 'sonner'

import * as S from './styles'

import { ApiError, filtersRemap, toastError, useToggle } from '@/common'
import {
  GeneralInfo,
  UploadImages,
  Specifications,
  CreateProductHelpDrawer,
  BgRegisterFirstStepHelp,
  BgRegisterSecondStepHelp,
  BgRegisterThirdStepHelp,
  DataValidation,
  BgRegisterFourthStepHelp,
  useCreateProductMutation,
  usePatchProductSpecificationsMutation,
  useSaveProductImagePack,
  CreateProductConfirmationModal,
  type BgFileUploadData,
  type CreateProductFormValues,
  type PatchProductSpecificationsMutation,
  type CreateProductMutation,
  createProductErrorMessages,
  CreateProductFirstStepHandler,
  useUpdateProductMutation,
  useUpdateProductImagePackMutation,
  UpdateProductImagePackMutation,
  SaveProductImagePackMutation,
  UpdateProductMutation,
  type ProductExistsResponse,
  type ProductExistsError,
  type UploadStatus,
  bgQueries,
} from '@/features/BG'
import { PageLayout } from '@/layouts'
import { api } from '@/services'

export const CreateProductPage = () => {
  const navigate = useNavigate()
  const { id: productId } = useParams()
  const [form] = Form.useForm()

  const { data: productData, isLoading: isLoadingProduct } = useQuery({
    ...bgQueries.detail(productId as string),
    enabled: !!productId,
  })

  const [currentStep, setCurrentStep] = useState(0)
  const [imageUploadStatus, setImageUploadStatus] = useState<UploadStatus>()
  const [imagePackData, setImagePackData] = useState<BgFileUploadData[]>([])
  const [formValues, setFormValues] = useState<CreateProductFormValues>(
    {} as CreateProductFormValues,
  )
  const [isProductHelpDrawerOpen, toggleProductHelpDrawer] = useToggle(false)
  const [isConfirmModalOpen, toggleConfirmModal] = useToggle(false)
  const [productExistsError, setProductExistsError] = useState<ProductExistsError>({
    refIdError: {
      status: false,
      message: '',
    },
    modelError: {
      status: false,
      message: '',
    },
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const createProductMutation = useCreateProductMutation()
  const updateProductMutation = useUpdateProductMutation()
  const patchProductSpecificationsMutation = usePatchProductSpecificationsMutation()
  const saveImagePackMutation = useSaveProductImagePack()
  const updateImagePackMutation = useUpdateProductImagePackMutation()

  const isCreatingProduct =
    createProductMutation.isPending ||
    patchProductSpecificationsMutation.isPending ||
    saveImagePackMutation.isPending

  const isEditingRegisteredProduct = !!productId && productData?.status !== 'EM RASCUNHO DO PRODUTO'

  useEffect(() => {
    if (productId) {
      const initialFormValues = {
        model: productData?.model,
        family: productData?.family,
        category: {
          key: productData?.category_id,
          label: productData?.category_name,
          value: productData?.category_id,
        },
        ref_id: productData?.ref_id,
        product_type: {
          key: productData?.product_type_vtex_id,
          label:
            filtersRemap.get(productData?.product_type_name as string) ||
            productData?.product_type_name,
          value: productData?.product_type_vtex_id,
        },
        template: '',
        overview: productData?.overview,
        has_upgrade: productData?.has_upgrade || false,
        external_characteristics: productData?.external_characteristics,
      }

      const initialImagesValue = productData?.sku?.sku_files?.map((file) => ({
        url: file.filename_url,
        filename: file.s3_filename,
        file: new File([], file.name, { type: 'image/jpeg' }),
      }))

      if (initialImagesValue) {
        setImagePackData(initialImagesValue)
        form.setFieldValue('bgFile', initialImagesValue)
      }

      form.setFieldsValue(initialFormValues)
    }
  }, [form, productData, productId])

  const steps = [
    {
      title: 'Informações Gerais',
      content: <GeneralInfo form={form} productExistsError={productExistsError} />,
      help: <BgRegisterFirstStepHelp />,
    },
    {
      title: 'Especificações',
      content: (
        <Specifications
          form={form}
          formValues={formValues}
          productType={
            (formValues?.product_type?.value as number) ||
            (productData?.product_type_vtex_id as number)
          }
          selectedTemplate={formValues?.template}
          infoButton={toggleProductHelpDrawer}
          initialValues={productData?.technical_specification}
        />
      ),
      help: <BgRegisterSecondStepHelp />,
      disabled: currentStep < 1 || isCreatingProduct,
    },
    {
      title: 'Imagens',
      content: (
        <UploadImages
          formValues={formValues}
          imagePackData={imagePackData}
          setImagePackData={setImagePackData}
          hasInitialValues={!!productId}
          uploadStatus={imageUploadStatus}
          setUploadStatus={setImageUploadStatus}
        />
      ),
      help: <BgRegisterThirdStepHelp />,
      disabled: currentStep < 2 || isCreatingProduct,
    },
    {
      title: 'Validação dos dados',
      content: (
        <DataValidation
          formValues={formValues}
          status={productData?.status || 'EM RASCUNHO DO PRODUTO'}
          imagePackData={imagePackData}
        />
      ),
      help: <BgRegisterFourthStepHelp />,
      disabled: currentStep < 3 || isCreatingProduct,
    },
  ]

  async function checkProductExists(model: string, refId: string) {
    const { data } = await api.get<ProductExistsResponse>(
      `/products/pn_or_model_exists?ref_id=${refId}&model=${model}`,
    )

    return data
  }

  function handleReturnStep() {
    if (currentStep !== 0) {
      return setCurrentStep((old) => old - 1)
    }
    return navigate('/')
  }

  function finishSubmission() {
    const formFieldsValues = form.getFieldsValue()

    setFormValues((old) => ({ ...old, ...formFieldsValues }))
    setCurrentStep((currentStep) => currentStep + 1)
    setIsSubmitting(false)
  }

  async function handleSubmit(values: any) {
    setIsSubmitting(true)

    const isEditingProduct = !!productId

    const isFirstStep = currentStep === 0
    const isLastStep = currentStep === 3
    const isSpecsStep = currentStep === 1
    const isImagePackStep = currentStep === 2

    try {
      if (isFirstStep) {
        const { model, ref_id } = values

        // check if model or ref_id ar already registered
        const { product_with_model_exists, product_with_ref_id_exists } = await checkProductExists(
          model,
          ref_id,
        )

        const setModelOrRefIdError = (field: string, condition: boolean, message: string) => {
          setProductExistsError((prevState) => ({
            ...prevState,
            [field]: condition ? { status: true, message } : { status: false, message: '' },
          }))

          return condition
        }

        const checkProductExistsErrors = ({ isEditing }: { isEditing: boolean }) => {
          const hasUneditedModel = isEditing && model === productData?.model
          const hasUneditedRefId = isEditing && ref_id === productData?.ref_id

          const modelError = setModelOrRefIdError(
            'modelError',
            product_with_model_exists && (!isEditing || !hasUneditedModel),
            'Modelo já cadastrado.',
          )

          const refIdError = setModelOrRefIdError(
            'refIdError',
            product_with_ref_id_exists && (!isEditing || !hasUneditedRefId),
            'Part number já cadastrado.',
          )

          return { modelError, refIdError, hasUneditedModel, hasUneditedRefId }
        }

        // check if the product is being edited
        if (isEditingProduct && productData) {
          const { modelError, refIdError, hasUneditedModel, hasUneditedRefId } =
            checkProductExistsErrors({ isEditing: true })

          if (hasUneditedModel && hasUneditedRefId) {
            return finishSubmission()
          }

          const isValid =
            (hasUneditedModel && !product_with_ref_id_exists) ||
            (hasUneditedRefId && !product_with_model_exists)

          if (isValid) {
            return finishSubmission()
          }

          if (modelError || refIdError) {
            throw new Error('ref_id ou modelo já cadastrado.')
          }
        } else {
          const { modelError, refIdError } = checkProductExistsErrors({ isEditing: false })

          if (modelError || refIdError) {
            throw new Error('ref_id ou modelo já cadastrado.')
          }
        }
      }

      if (isLastStep) {
        setFormValues((old) => ({ ...old, ...values }))

        if (isEditingRegisteredProduct) {
          return createProduct()
        }

        return toggleConfirmModal()
      }

      if (isSpecsStep) {
        const specificationValues = form.getFieldValue('specifications')

        // aqui eu separo os valores do form e as especificações dinamicas
        const {
          overview,
          has_upgrade,
          external_characteristics,
          ...productSpecifications
        }: Record<string, any> = {
          ...specificationValues,
          ...values,
        }

        const overviewsValues = form.getFieldValue('overview')
        const externalCharacteristicsValues = form.getFieldValue('external_characteristics')

        const isSpecsEmpty = Object.values(productSpecifications).every(
          (value) => !value || value === '<p></p>',
        )

        if (!overview) {
          toast.error('Por favor, preencha o resumo do produto antes de continuar')
          throw new Error('Resumo não preenchido.')
        }

        if (isSpecsEmpty) {
          toast.error('Por favor, preencha alguma especificação antes de continuar')
          throw new Error('Especificações não preenchidas.')
        }

        setFormValues((old) => ({
          ...old,
          specifications: productSpecifications,
          overview: overviewsValues || overview,
          has_upgrade: has_upgrade,
          external_characteristics: externalCharacteristicsValues || external_characteristics,
        }))
      }

      if (isImagePackStep) {
        const isValid = imagePackData.length > 0

        if (!isValid) {
          toast.error('Por favor, adicione ao menos uma imagem antes de continuar.')
          throw new Error('Nenhuma imagem adicionada.')
        }
      }

      finishSubmission()
    } catch (err) {
      console.error('Error submitting form:', err)
      setIsSubmitting(false)
    }
  }

  async function handleFirstStep(values: CreateProductFirstStepHandler) {
    const { model, family, category, ref_id } = values

    const payload: CreateProductMutation = {
      model,
      family,
      category_id: category.value,
      ref_id,
    }

    let promise: Promise<AxiosResponse<{ id: string }>> | undefined = undefined

    if (productId) {
      const updateProductPayload: UpdateProductMutation = {
        ...payload,
        productId: productId,
      }

      promise = updateProductMutation.mutateAsync(updateProductPayload)
    } else {
      promise = createProductMutation.mutateAsync(payload)
    }

    const { data } = (await promise) as AxiosResponse<{ id: string }>

    return data.id
  }

  async function handleSecondStep(payload: PatchProductSpecificationsMutation) {
    patchProductSpecificationsMutation.mutateAsync(payload)
  }

  async function uploadImagePack(imagePackData: BgFileUploadData[]) {
    if (imagePackData.length === 0) {
      toast.error('Nenhum imagem encontrada.')
      throw new Error('Nenhum imagem encontrada.')
    }

    const uploadResults: { success: number; errors: number; failedMessages: string[] } = {
      success: 0,
      errors: 0,
      failedMessages: [],
    }

    const promises = imagePackData.map((data) => {
      return axios
        .put(data.url, data?.file, {
          headers: {
            'Content-Type': data?.file?.type,
          },
        })
        .then((response) => {
          uploadResults.success++
          return response
        })
        .catch((error) => {
          uploadResults.errors++
          uploadResults.failedMessages.push(
            `Falha no arquivo ${data?.file?.name}: ${error.message}`,
          )
          console.error(`ERROR during file upload ${data?.file?.name}:`, error)
          return error
        })
    })

    await Promise.all(promises)

    if (uploadResults.errors > 0) {
      toast.error(`Alguns arquivos não foram enviados corretamente.`)
      uploadResults.failedMessages.forEach((msg) => console.log(msg))
      throw new Error('Erro ao enviar alguns arquivos.')
    } else {
      // toast.success('Pack de imagens salvo com sucesso!')
    }
  }

  async function handleThirdStep(createdProductId: string, saveAsDraft = false) {
    const imagesToUpload = imagePackData.filter((data) => data.file && data.file.size > 0)

    const hasSkus = productData?.sku && productData?.sku?.sku_files?.length > 0 // already created sku in database
    const isEditingImages = !!productId && hasSkus

    if (imagesToUpload.length > 0) {
      await uploadImagePack(imagesToUpload)
    }

    if (isEditingImages) {
      // if already has SKU, update the image pack
      const excludedFiles = form.getFieldValue('excludedFiles')

      const updateImagePackPayload: UpdateProductImagePackMutation = {
        productId: productId as string,
        saveAsDraft,
        files_excludeds: excludedFiles || [],
        files_includeds: imagePackData?.map((data) => data.filename),
      }

      return updateImagePackMutation.mutateAsync(updateImagePackPayload)
    } else {
      // if not, create the sku & post the images
      const payload: SaveProductImagePackMutation = {
        productId: createdProductId,
        saveAsDraft,
        images: imagePackData?.map((data) => data.filename),
      }

      return saveImagePackMutation.mutateAsync(payload)
    }
  }

  async function handleCreateProductOperations(
    productValues: any,
    saveAsDraftStep?: 'info' | 'specs' | 'imagePack',
  ) {
    const createProductPayload = {
      product_type: productValues?.product_type?.value,
      category: productValues?.category,
      model: productValues?.model,
      family: productValues?.family,
      ref_id: productValues?.ref_id,
      productId: productId,
    }

    // Start by creating the product and obtain the product ID
    const createdProductId = await handleFirstStep(createProductPayload)

    if (saveAsDraftStep && saveAsDraftStep === 'info') {
      return createdProductId
    }

    // Handle product specifications next
    const productSpecificationsPayload = {
      productId: createdProductId,
      overview: productValues.overview,
      external_characteristics: productValues.external_characteristics,
      saveAsTemplate: productValues?.save_as_template || false,
      technical_specification: productValues.specifications,
      has_upgrade: productValues?.has_upgrade || form.getFieldValue('has_upgrade') || false,
    }

    await handleSecondStep(productSpecificationsPayload)

    if (saveAsDraftStep && saveAsDraftStep === 'specs') {
      return createdProductId
    }

    // Lastly, handle the image pack uploading
    await handleThirdStep(createdProductId, !!saveAsDraftStep)

    return createdProductId // Return the product ID to signify success
  }

  async function handleSaveDraft() {
    const isInfoStep = currentStep === 0
    const isSpecsStep = currentStep === 1
    const isImagePackStep = currentStep === 2

    try {
      await form.validateFields()

      let saveAsDraftPromise: Promise<string> | undefined = undefined

      const values = form.getFieldsValue()

      if (isInfoStep) {
        saveAsDraftPromise = handleCreateProductOperations(values, 'info')
      }

      if (isSpecsStep || isImagePackStep) {
        const saveAsDraftStep = isSpecsStep ? 'specs' : 'imagePack'

        const specificationValues = form.getFieldValue('specifications')

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {
          overview,
          external_characteristics,
          ...productSpecifications
        }: Record<string, any> = {
          ...specificationValues,
          ...values,
        }

        const productValues = {
          ...formValues,
          overview: overview || formValues?.overview,
          external_characteristics:
            external_characteristics || formValues?.external_characteristics,
          specifications: productSpecifications,
        }

        saveAsDraftPromise = handleCreateProductOperations(productValues, saveAsDraftStep)
      }

      if (saveAsDraftPromise) {
        toast.promise(saveAsDraftPromise, {
          loading: 'Salvando rascunho',
          success: (
            <S.ToastContainer>
              <S.CircleOutlined />

              <div>
                Rascunho <span style={{ fontWeight: 'bold' }}>salvo</span> com{' '}
                <span style={{ fontWeight: 'bold' }}>sucesso</span>!
              </div>
            </S.ToastContainer>
          ),
          className: 'toast-sucess',
          error: (error: ApiError) =>
            toastError({
              error,
              defaultMessage: 'Falha ao salvar rascunho.',
              errorMessages: createProductErrorMessages,
            }),
        })

        await saveAsDraftPromise

        navigate('/')
      }
    } catch (err) {
      console.error('Error saving draft', err)
    }
  }

  async function createProduct() {
    const productValues = { ...formValues }

    try {
      const createProductPromise = handleCreateProductOperations(productValues)

      const loadingMessage = isEditingRegisteredProduct
        ? 'Atualizando o BG...'
        : 'Criando e salvando os detalhes do BG...'

      toast.promise(createProductPromise, {
        loading: loadingMessage,
        success: (
          <S.ToastContainer>
            <S.CircleOutlined />

            <div>
              BG{' '}
              <span style={{ fontWeight: 'bold' }}>
                {isEditingRegisteredProduct ? 'atualizado' : 'criado'}
              </span>{' '}
              com <span style={{ fontWeight: 'bold' }}>sucesso</span>!
            </div>
          </S.ToastContainer>
        ),
        className: 'toast-sucess',
        error: (error: ApiError) =>
          toastError({
            error,
            defaultMessage: 'Falha ao criar o BG.',
            errorMessages: createProductErrorMessages,
          }),
      })

      await createProductPromise

      navigate('/')
    } catch (err) {
      console.error('Failed to create product:', err)
    }
  }

  const ButtonsContainer = () => {
    return (
      <S.PageButtons>
        <S.BackButton type="link" icon={<ArrowLeftOutlined />} onClick={handleReturnStep}>
          Voltar
        </S.BackButton>

        <S.HeaderActions>
          {currentStep < 2 && !isEditingRegisteredProduct && (
            <S.Button
              type="default"
              icon={<FormOutlined />}
              onClick={handleSaveDraft}
              disabled={(!!productId && isLoadingProduct) || imageUploadStatus === 'uploading'}
            >
              Salvar Rascunho
            </S.Button>
          )}

          <S.Button type="default" icon={<FilePdfOutlined />} disabled>
            Exportar em Docx
          </S.Button>

          {currentStep === 3 ? (
            <S.Button
              type="primary"
              icon={<FileProtectOutlined />}
              onClick={() => form.submit()}
              loading={isCreatingProduct}
            >
              {isEditingRegisteredProduct ? 'Salvar alterações' : 'Cadastrar'}
            </S.Button>
          ) : (
            <S.Button
              type="primary"
              icon={<FiFastForward />}
              onClick={() => form.submit()}
              loading={isSubmitting || imageUploadStatus === 'uploading'}
              disabled={!!productId && isLoadingProduct}
            >
              Continuar
            </S.Button>
          )}
        </S.HeaderActions>
      </S.PageButtons>
    )
  }

  return (
    <PageLayout title="Cadastrar Produto">
      {isProductHelpDrawerOpen && (
        <CreateProductHelpDrawer isOpen={isProductHelpDrawerOpen} onClose={toggleProductHelpDrawer}>
          {steps[currentStep].help}
        </CreateProductHelpDrawer>
      )}

      {isConfirmModalOpen && (
        <CreateProductConfirmationModal
          isOpen={isConfirmModalOpen}
          onClose={toggleConfirmModal}
          onConfirm={createProduct}
          productName={formValues.model}
          isLoading={isCreatingProduct}
        />
      )}

      <S.Container>
        <ButtonsContainer />

        <S.PageTitle>Cadastro do BG</S.PageTitle>

        <Steps current={currentStep} items={steps} onChange={(step) => setCurrentStep(step)} />

        {productId && isLoadingProduct ? (
          <Skeleton active />
        ) : (
          <>
            <S.Card>
              <S.CardContent $currentStep={currentStep}>
                <Form
                  form={form}
                  onFinish={handleSubmit}
                  layout="vertical"
                  validateTrigger="onChange"
                  requiredMark="optional"
                  disabled={isSubmitting || isCreatingProduct}
                >
                  {steps[currentStep]?.content}
                </Form>

                {(currentStep === 0 || currentStep === 2) && (
                  <S.HelpButton type="link" onClick={toggleProductHelpDrawer}>
                    <Tooltip title="Instruções de preenchimento" placement="left">
                      <S.TooltipHelpIcon>
                        <BsQuestionLg />
                      </S.TooltipHelpIcon>
                    </Tooltip>
                  </S.HelpButton>
                )}
              </S.CardContent>
            </S.Card>

            {currentStep === 3 && <ButtonsContainer />}
          </>
        )}
      </S.Container>
    </PageLayout>
  )
}
