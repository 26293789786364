import { useMutation, useQueryClient } from '@tanstack/react-query'

import { bgQueries } from '../queries'

import { api } from '@/services'

export const useIsAvailableToAgencyMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (productId: string) => {
      return api.patch(`/products/${productId}/available_to_agency`)
    },
    onMutate: async () => await queryClient.cancelQueries({ queryKey: bgQueries.all() }),
    onSettled: () => queryClient.invalidateQueries({ queryKey: bgQueries.all() }),
  })
}
