import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const BGLabel = styled.span`
  font-size: ${rem(20)};
  font-weight: 600;
  line-height: 1.5rem;
  text-align: left;
`

export const BGDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  padding: 10px;
  gap: 16px;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${rem(180)};
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
`

export const Label = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #6c6c6c;
`

export const Title = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #434343;
`

export const DataText = styled.span`
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;

  color: #434343;
`

export const DescriptionContainer = styled.div`
  max-height: 300px;
  overflow: auto;

  pre {
    max-width: 600px;
  }
`

export const DescriptionDataText = styled(DataText)`
  font-weight: 500;
`

export const BGDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

export const BGFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  padding: 10px;
  flex: 1;
  gap: 16px;
`

export const DetailsRow = styled.div`
  display: flex;
`

export const FilterIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const SecondColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const BGIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  padding: 10px;
  flex: 1;
  gap: 16px;
`

export const BGImagePackContainer = styled.div`
  display: flex;
  gap: 8px;
  background-color: #fff;
  padding: 10px 16px;

  border-radius: 4px;
  border: 1px solid #e5e5e5;

  width: 100%;
  max-width: calc(100vw - 68px);
  overflow: auto;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const IMGLabel = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;

  color: #0c6dff;
`
export const SanitizeHtmlTag = styled.pre`
  font-size: 14px;
`
