import { Button as AntdButton } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  .ant-form-item {
    flex: 1;
    margin-bottom: 10px;
  }

  .ant-form-item-label > label {
    color: #6c6c6c;
  }

  .ant-input {
    height: 32px;
  }

  .ant-select-single.ant-select-lg .ant-select-selector {
    font-size: 14px;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  padding-left: 20px;

  background-color: #f8f8f8;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  .agency-name-input {
    width: ${rem(348)};
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-right: 10px;
  gap: 22px;
  width: 100%;
`

export const AddButton = styled(AntdButton)`
  margin-top: ${rem(28)};
`
